import { createSlice } from '@reduxjs/toolkit';
import { 
  //fetchProposals,
  //getProposals,
  //createProposals,
  salesProposalsActions
 } from './proposalActions';

interface ProposalState {
  proposalDetailsList: any[];
  filterData: any;
  autocompleteprposal: any;
  projectContactDetails:any;
  createProposals: any[];
  singleProposal: any[];
  paymentSchedule:any[];
  savedPaymentSchedule: any;
  loading: boolean;
  success: any;
  error: any;
  page: number;
  perPage: number;
  activePagination: any,
  terms: any,
  proposalsDetails:any,
  proposalsMasterItems: any,
  proposalItemsDetails: [],
  resetProposalItems: boolean,
  proposalPolycamDetails: [],
  proposalItemsDetailsSaved: null,
  proposalItemInfo: any,
  proposalSaveItemInfo: null,
  exportPdf: null,
}

const initialState: ProposalState = {
  proposalDetailsList: [],
  filterData: null,
  autocompleteprposal: null,
  projectContactDetails: null,
  createProposals: [],
  singleProposal: [],
  paymentSchedule: [],
  savedPaymentSchedule: null,
  loading: false,
  success: null,
  error: null,
  page: 1,
  perPage: 5,
  activePagination: {},
  terms: [],
  proposalsDetails: null,
  proposalsMasterItems: null,
  proposalItemsDetails: [],
  resetProposalItems: true,
  proposalPolycamDetails: [],
  proposalItemsDetailsSaved: null,
  proposalItemInfo: null,
  proposalSaveItemInfo: null,
  exportPdf: null,
};

const proposalSlice = createSlice({
  name: 'proposals',
  initialState,
  reducers: {
    resetSalesProposalsStatus: (state:any) => {
      state.success = null;
      state.error = null;
    },
    cleanProposalDetails: (state) => {
      state.proposalsDetails = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // -------- cases of get proposal list api data -------- //
      .addCase(salesProposalsActions.getProposalsListAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.proposalDetailsList = [];
        // clearing proposal details data
        state.proposalsDetails = null;
        state.success = null
      })
      .addCase(salesProposalsActions.getProposalsListAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.proposalDetailsList = action?.payload?.data?.data;
          state.filterData = action?.meta?.arg?.filters;
          state.activePagination = action?.payload?.data?.pagination;
          state.success = action?.payload?.data?.message;
          state.error = null;
        }else{
          state.proposalDetailsList = [];
          state.success = null;
          state.error = action?.payload?.data?.message ?? 'Failed to fetch proposals';
        }
      })
      .addCase(salesProposalsActions.getProposalsListAction.rejected, (state, action:any) => {
        state.loading = false;
        state.proposalDetailsList = [];
        state.success = null;
        state.error = action?.payload?.data?.message ?? 'Failed to fetch proposals';
      })

      // -------- Cases for proposal details api data -------- //
      .addCase(salesProposalsActions.getProposalDetailsAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.proposalsDetails = null;
        state.activePagination = []
      })
      .addCase(salesProposalsActions.getProposalDetailsAction.fulfilled, (state, action) => {
        state.loading = false;
        state.proposalsDetails = action.payload.data.data;
        state.success = action.payload.data.data;
        state.activePagination = action.payload.data.pagination
      })
      .addCase(salesProposalsActions.getProposalDetailsAction.rejected, (state, action:any) => {
        state.loading = false;
        state.proposalsDetails = null;
        state.success = null;
        state.error = action?.payload?.data ?? 'Failed to fetch proposals';
      })

      // Get proposal Item Details
      .addCase(salesProposalsActions.getProposalItemDetailsAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        if(action?.meta?.arg?.reset){
          state.proposalItemsDetails = [];
        }
      })
      .addCase(salesProposalsActions.getProposalItemDetailsAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.proposalItemsDetails = action.payload.data.data;
          if(action?.meta?.arg?.loader){
            state.resetProposalItems = true;
          }else{
            state.resetProposalItems = false;
          }
        }else{
          state.proposalItemsDetails = [];
          state.error = action?.payload?.data?.message ?? 'Failed to fetch proposal Items';  
        }
      })
      .addCase(salesProposalsActions.getProposalItemDetailsAction.rejected, (state, action:any) => {
        state.loading = false;
        state.proposalItemsDetails = [];
        state.error = action?.payload?.data?.message ?? 'Failed to fetch proposal Items';
      })

      // Get proposal room item Details fro polycam overview
      .addCase(salesProposalsActions.getProposalPolycamDetailsAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        if(action?.meta?.arg?.reset){
          state.proposalPolycamDetails = [];
        }
      })
      .addCase(salesProposalsActions.getProposalPolycamDetailsAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.proposalPolycamDetails = action.payload.data.data;
        }else{
          state.proposalPolycamDetails = [];
          state.error = action?.payload?.data?.message ?? 'Failed to fetch polycam Items';  
        }
      })
      .addCase(salesProposalsActions.getProposalPolycamDetailsAction.rejected, (state, action:any) => {
        state.loading = false;
        state.proposalPolycamDetails = [];
        state.error = action?.payload?.data?.message ?? 'Failed to fetch polycam Items';
      })

      // proposals Autocomplete
      .addCase(salesProposalsActions.proposalsAutocompleteAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.autocompleteprposal= null
      })
      .addCase(salesProposalsActions.proposalsAutocompleteAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.autocompleteprposal = action?.payload?.data;
        }else{
          state.autocompleteprposal= [];
          state.error = action?.payload?.data?.message ?? 'Failed to fetch proposals';  
        }
      })
      .addCase(salesProposalsActions.proposalsAutocompleteAction.rejected, (state, action:any) => {
        state.loading = false;
        state.autocompleteprposal= [];
        state.error = action?.payload?.data ?? 'Failed to fetch proposals';
      })

      // Check project contact details
      .addCase(salesProposalsActions.getProjectContactDetailsAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.projectContactDetails = null;
      })
      .addCase(salesProposalsActions.getProjectContactDetailsAction.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.projectContactDetails = action?.payload?.data;
      })
      .addCase(salesProposalsActions.getProjectContactDetailsAction.rejected, (state, action:any) => {
        state.loading = true;
        state.error = action?.payload?.data;
        state.projectContactDetails = null;
      })

      // Create proposal
      .addCase(salesProposalsActions.createProposalAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(salesProposalsActions.createProposalAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.createProposals = action.payload.data.data;
          state.success = action.payload.data.data;
        }else{
          state.createProposals = [];
          state.success = null;
          state.error = action?.payload?.data ?? 'Proposal save failed';  
        }
      })
      .addCase(salesProposalsActions.createProposalAction.rejected, (state, action:any) => {
        state.loading = false;
        state.createProposals = [];
        state.success = null;
        state.error = action?.payload?.data ?? 'Proposal save failed';
      })

      // save payment schedule
      .addCase(salesProposalsActions.savePaymentScheduleAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(salesProposalsActions.savePaymentScheduleAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status ===200){
          state.savedPaymentSchedule = action.payload.data;
          state.success = action?.payload
        }else{
          state.success = null;
          state.error = action?.payload?.data ?? 'Save Payment Failed';
        }
      })
      .addCase(salesProposalsActions.savePaymentScheduleAction.rejected, (state, action:any) => {
        state.loading = false;
        state.success = null;
        state.error = action?.payload?.data ?? 'Save Payment Failed';
      })

      // get payment schedule
      .addCase(salesProposalsActions.getPaymentScheduleAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.paymentSchedule = [];
      })
      .addCase(salesProposalsActions.getPaymentScheduleAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.paymentSchedule = action.payload.data.data;
          state.success = action.payload.data.data;
        }else{
          state.success = null;
          state.paymentSchedule = [];
          state.error = action?.payload?.data ?? 'Get Payment Failed';
        }
      })
      .addCase(salesProposalsActions.getPaymentScheduleAction.rejected, (state, action:any) => {
        state.loading = false;
        state.success = null;
        state.paymentSchedule = [];
        state.error = action?.payload?.data ?? 'Get Payment Failed';
      })

      // get terms
      .addCase(salesProposalsActions.getTermsAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.terms = [];
      })
      .addCase(salesProposalsActions.getTermsAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.terms = action.payload.data.data;
        }else{
          state.terms = [];  
          state.error = action?.payload?.data?.message ?? 'Get Payment Failed';
        }
      })
      .addCase(salesProposalsActions.getTermsAction.rejected, (state, action:any) => {
        state.loading = false;
        state.terms = [];
        state.error = action?.payload?.data?.message ?? 'Get Payment Failed';
      })

      // save custom exclusion
      .addCase(salesProposalsActions.saveCustomExclusionAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(salesProposalsActions.saveCustomExclusionAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status!==200){
          state.error = action?.payload?.data?.message ?? 'Save Custom Exclusion Failed';
        }
      })
      .addCase(salesProposalsActions.saveCustomExclusionAction.rejected, (state, action:any) => {
        state.loading = false;
        state.error = action?.payload?.data?.message ?? 'Save Custom Exclusion Failed';
      })

      // save notes
      .addCase(salesProposalsActions.saveNotesAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(salesProposalsActions.saveNotesAction.fulfilled, (state, action) => {
        state.loading = false;
        // state.terms = action.payload.data;
        state.error = null
      })
      .addCase(salesProposalsActions.saveNotesAction.rejected, (state, action:any) => {
        state.loading = false;
        state.error = action?.payload?.data ?? 'Save Notes Failed';
      })

      // -------- cases of get master items api data -------
      .addCase(salesProposalsActions.getMasterItemsAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.proposalsMasterItems = null;
      })
      .addCase(salesProposalsActions.getMasterItemsAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.proposalsMasterItems = action.payload.data.data;
          state.error = null
        }else{
          state.loading = false;
          state.error = action?.payload?.data ?? 'Get master items Failed';
        }
      })
      .addCase(salesProposalsActions.getMasterItemsAction.rejected, (state, action:any) => {
        state.loading = false;
        state.error = action?.payload?.data ?? 'Get master items Failed';
      })

      // save proposal items
      .addCase(salesProposalsActions.saveProposalItemsAction.pending, (state) => {
        state.loading = true;
        state.success = null;
        state.error = null;
        state.proposalItemsDetailsSaved = null;
      })
      .addCase(salesProposalsActions.saveProposalItemsAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.proposalItemsDetails = action.payload.data.data.items;
          
          // upate proposal details below
          const proposalDetails = action.payload.data.data
          state.proposalsDetails = {
            ...state.proposalsDetails,
            expirationType: proposalDetails.expirationType,
            expiresIn: proposalDetails.expiresIn,
            noReset: true
          }

          if(action?.meta?.arg?.loader){
            state.resetProposalItems = true;
          }else{
            state.resetProposalItems = false;
          }

          state.proposalItemsDetailsSaved = action?.payload?.data;
          state.success = action?.payload?.data;
        }else{
          state.proposalItemsDetailsSaved = null
          state.success = null;
          state.error = action?.payload?.data ?? 'Save proposal items Failed';  
        }
      })
      .addCase(salesProposalsActions.saveProposalItemsAction.rejected, (state, action:any) => {
        state.loading = false;
        state.proposalItemsDetailsSaved = null
        state.success = null;
        state.error = action?.payload?.data ?? 'Save proposal items Failed';
      })

      // save proposal room details
      .addCase(salesProposalsActions.saveRoomDetailsAction.pending, (state) => {
        state.loading = true;
        state.success = null;
        state.error = null;
      })
      .addCase(salesProposalsActions.saveRoomDetailsAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.success = action?.payload?.data;
        }else{
          state.success = null;
          state.error = action?.payload?.data ?? 'Save proposal room Failed';  
        }
      })
      .addCase(salesProposalsActions.saveRoomDetailsAction.rejected, (state, action:any) => {
        state.loading = false;
        state.success = null;
        state.error = action?.payload?.data ?? 'Save proposal room Failed';
      })

      // --------get proposal items --------//
      .addCase(salesProposalsActions.getProposalItemAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.proposalItemInfo = null;
      })
      .addCase(salesProposalsActions.getProposalItemAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.proposalItemInfo = action?.payload?.data?.data;
          state.success = action?.payload?.data?.data;
          state.error = null
        }else{
          state.proposalItemInfo = null;
          state.success = null;
          state.error = action?.payload?.data ?? 'Get proposal items Failed';
        }
      })
      .addCase(salesProposalsActions.getProposalItemAction.rejected, (state, action:any) => {
        state.loading = false;
        state.proposalItemInfo = null;
        state.success = null;
        state.error = action?.payload?.data ?? 'Get proposal items Failed';
      })

      // --------save proposal items info --------//
      .addCase(salesProposalsActions.saveProposalItemInfoAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.proposalSaveItemInfo = null;
      })
      .addCase(salesProposalsActions.saveProposalItemInfoAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.proposalSaveItemInfo = action?.payload?.data;
          state.success = action?.payload?.data?.message;
          state.error = null
        }else{
          state.proposalSaveItemInfo = null;
          state.success = null;
          state.error = action?.payload?.data?.message ?? 'Save proposal items info Failed';
        }
      })
      .addCase(salesProposalsActions.saveProposalItemInfoAction.rejected, (state, action:any) => {
        state.loading = false;
        state.proposalSaveItemInfo = null;
        state.success = null;
        state.error = action?.payload?.data?.message ?? 'Save proposal items info Failed';
      })

      // -------- export PDF --------//
      .addCase(salesProposalsActions.exportPDFAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.exportPdf = null;
      })
      .addCase(salesProposalsActions.exportPDFAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.exportPdf = action?.payload?.data;
          state.success = action?.payload?.data;
          state.error = null;
        }else{
          state.exportPdf = null;
          state.success = null;
          state.error = action?.payload?.data?.message ?? 'Export pdf Failed';
        }
      })
      .addCase(salesProposalsActions.exportPDFAction.rejected, (state, action:any) => {
        state.loading = false;
        state.exportPdf = null;
        state.success = null;
        state.error = action?.payload?.data?.message ?? 'Export pdf Failed';
      })

      // -------- unrelease proposal --------//
      .addCase(salesProposalsActions.unreleaseProposalAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(salesProposalsActions.unreleaseProposalAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.success = action?.payload?.data;
          state.error = null;
        }else{
          state.success = null;
          state.error = action?.payload?.data?.message ?? 'Unrelease proposal Failed';
        }
      })
      .addCase(salesProposalsActions.unreleaseProposalAction.rejected, (state, action:any) => {
        state.loading = false;
        state.success = null;
        state.error = action?.payload?.data?.message ?? 'Unrelease proposal Failed';
      })

      // -------- template proposal --------//
      .addCase(salesProposalsActions.templateProposalAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(salesProposalsActions.templateProposalAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.success = action?.payload?.data;
          state.error = null;
        }else{
          state.success = null;
          state.error = action?.payload?.data?.message ?? 'Failed to create proposal template';
        }
      })
      .addCase(salesProposalsActions.templateProposalAction.rejected, (state, action:any) => {
        state.loading = false;
        state.success = null;
        state.error = action?.payload?.data?.message ?? 'Failed to create proposal template';
      })

      // -------- upload pdf for sign proposal api data -------
      .addCase(salesProposalsActions.uploadPdfForSignProposalAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(salesProposalsActions.uploadPdfForSignProposalAction.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data?.status===200) {
          state.success = action?.payload?.data?.message;
          state.error = null;
        } else {
          state.error = action?.payload?.data?.message ?? 'Failed to upload';
        }
      })
      .addCase(salesProposalsActions.uploadPdfForSignProposalAction.rejected, (state, action:any) => {
        state.success = null;
        state.error = action?.payload?.data?.message ?? 'Failed to upload';
      })

      // -------- created proposal list api data -------
      .addCase(salesProposalsActions.createdProposalsListAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(salesProposalsActions.createdProposalsListAction.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data?.status===200) {
          state.success = action?.payload?.data?.message;
          state.error = null;
        } else {
          state.error = action?.payload?.data?.message ?? 'Failed to fetch created proposal list';
        }
      })
      .addCase(salesProposalsActions.createdProposalsListAction.rejected, (state, action:any) => {
        state.success = null;
        state.error = action?.payload?.data?.message ?? 'Failed to fetch created proposal list';
      })

      // -------- selected proposal room list api data -------
      .addCase(salesProposalsActions.selectedProposalRoomListAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(salesProposalsActions.selectedProposalRoomListAction.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data?.status===200) {
          state.success = action?.payload?.data?.message;
          state.error = null;
        } else {
          state.error = action?.payload?.data?.message ?? 'Failed to fetch room list';
        }
      })
      .addCase(salesProposalsActions.selectedProposalRoomListAction.rejected, (state, action:any) => {
        state.success = null;
        state.error = action?.payload?.data?.message ?? 'Failed to fetch room list';
      })

      // -------- import room data -------
      .addCase(salesProposalsActions.importRoomAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(salesProposalsActions.importRoomAction.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data?.status===200) {
          state.success = action?.payload?.data?.message;
          state.error = null;
        } else {
          state.error = action?.payload?.data?.message ?? 'Failed to import room';
        }
      })
      .addCase(salesProposalsActions.importRoomAction.rejected, (state, action:any) => {
        state.success = null;
        state.error = action?.payload?.data?.message ?? 'Failed to import room';
      })

      // -------- proposal export slice -------
      .addCase(salesProposalsActions.proposalExportAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(salesProposalsActions.proposalExportAction.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data?.status===200) {
          state.success = action?.payload?.data?.message;
          state.error = null;
        } else {
          state.error = action?.payload?.data?.message ?? 'Failed to export proposal';
        }
      })
      .addCase(salesProposalsActions.proposalExportAction.rejected, (state, action:any) => {
        state.success = null;
        state.error = action?.payload?.data?.message ?? 'Failed to export proposal';
      })
  },
});

export const { resetSalesProposalsStatus, cleanProposalDetails } = proposalSlice.actions;
export default proposalSlice.reducer;
