import mongoose from "mongoose";

export const formatedDate = (date: any) => {
  date = new Date(date);
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const year = date.getUTCFullYear();

  return `${month}/${day}/${year}`;
};

// helper function to asynchronously clear local storage
export function clearLocalStorageAsync() {
  return new Promise((resolve:any, reject:any) => {
    try {
      localStorage.clear();
      resolve();
    } catch (error) {
      reject(error);
    }
  });
}

// helper function to check if entered values are decimal
export const acceptDecimal = (value: string) => {
  const regex = /^[0-9]*\.?[0-9]*$/; // regex to allow decimal and numbers only
  if (regex.test(value)) {
    return true
  }
  return false;
}

// measurement msking function
export const maskInput = (input: string) => {
  return input;
}

export const modifyListForAutocomplete = (list: any | []) => {
  const result = list.map((item: any) => {
    return {
      label: item?.title,
      value: item?.id
    }
  });
  return result;
}

const toFixedNoRound = (number:any, precision:any) =>{
  if(number%1 === 0){
    return number.toFixed(precision);
  }
  const factor = Math.pow(10, precision);
  const num = Math.floor(Number((number * factor).toFixed(0)))/factor
  return num.toFixed(2)
}

// amount mask
export const amountMask = (input: any, currencySign?: string) => {
  let valSign = '';
  const amount = input === 0 ? "0.00" : input === null ? "0.00" : typeof input === "string" ? input : toFixedNoRound(input, 2);
  
  // extract '-' sign if it has been entered.
  valSign = amount?.slice(0, 1) === '-' ? amount.slice(0, 1): '';
  if(valSign){
    return `${valSign}${currencySign ?? ''}${amount.toString().slice(1).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  }
  return `${valSign}${currencySign ?? ''}${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

// chapter cost
export const chapterCost = (unitCost: number, quantity: number) => {
  const result: any = unitCost * quantity
  return amountMask(result?.toFixed(2))
}

 // calculate owner price
export const calculateOwnerPrice = (unitCost: number, quantity: number, markup: number, markupType: any) => {
  const chapterCost = unitCost * quantity
  let result: any = 0;

  if (markupType === "%") {
      if (markup === 0 || isNaN(markup)) {
          result = chapterCost;
      } else {
          result = (chapterCost * (markup / 100)) + chapterCost;
      }
  } else {
      if (isNaN(markup)) {
          result = chapterCost;
      } else {
          result = Number(chapterCost) + Number(markup);
      }
  }
  return result.toFixed(2)
  // return amountMask(result.toFixed(2));
}

// calculate non itemized cost
export const calculateNonItemizedTotalCost = (array: any, groupIndex: any) => {
  let totalOwnerPrice = 0;

  const firstGroup = array[groupIndex]; // Get the first group

  firstGroup.subcategories.forEach((item: any) => {
          const chapterCost = parseFloat(item.unitCost) * parseFloat(item.quantity);
          const ownerPrice =
              item.markupType === "%"
                  ? chapterCost * (item.markup / 100) + chapterCost
                  : Number(chapterCost) + Number(item.markup);
          totalOwnerPrice += ownerPrice || 0;
  });

  return amountMask(totalOwnerPrice.toFixed(2));
};

// get initials from full name
export const getInitials = (name: string) => {
  let init1;
  let init2;
  if (name === null || name === undefined) {
    init1 = "A";
    init2 = "A";
  } else {
    init1 = name?.split(' ')[0].slice(0, 1);
    init2 = name?.split(' ')[1].slice(0, 1);
  }
  return `${init1} ${init2}`;
}

//mask length on change input
export const maskLength = (value: any) => {
  let inputValue;
  inputValue = value.replace(/\D/g, "")
    .replace(/(\d{2})/, `$1'`)
    .replace(/(\d{2})'([23456789])/, `$1'0$2`)
    .replace(/'(\d{2})/, `'$1"`)
    // .replace(/(\d{2})'([23456789])/, `$1'1`)
    .replace(/('[1])([23456789])"/, `$1`)
    // .replace(/('[0])(\d)/, `$1$2"`)
    .replace(/('[1])([01])/, `$1$2`)
    .replace(/('\d{2})"(\d)/, `$1"`)
    .replace(/("\d)/, `"`)

  return inputValue;
}

// mask length on blur
export const maskLengthOnBlur = (value: any) => {
  let inputValue;
  if (value?.length < 2) {
    inputValue = value
      .replace(/\D/g, "")
      .replace(/(\d{1})/, `0$1'00"`);
  } else if (value?.length < 4) {
    inputValue = value
      .replace(/\D/g, "")
      .replace(/(\d{2})/, `$1'00"`);
  } else if (value?.length < 5) {
    if (/(\d{1})'(\d{2})/.test(value)) {
      inputValue = value
        .replace(/(\d{1})'(\d{2})/, `0$1'$2"`)
    } else {
      inputValue = value
        .replace(/(\d{2})'(\d{1})/, `$1'0$2"`)
    }
  } else {
    inputValue = value
  }
  return inputValue;
}

const compareUrls = (url1:string, url2:string) => {

  function markUUIDsInURL(url:string) {
      // Regular expression to match UUIDs
      const uuidRegex = /[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}/g;
      // Replace UUIDs with "<UUID>"
      let markedURL = url.replace(uuidRegex, "<UUID>");    
      // Regular expression to match Mongoose ObjectIDs
      const objectIdRegex = /[0-9a-fA-F]{24}/g;
      // Replace ObjectIDs with "<ID>"
      markedURL = markedURL.replace(objectIdRegex, (match) => {
          // Check if the match is a valid ObjectID
          if (mongoose.Types.ObjectId.isValid(match)) {
              return "<UUID>";
          }
          return match; // Return the original match if not a valid ObjectID
      });
      return markedURL;
  }

  // Remove UUIDs from both URLs
  const cleanUrl1 = markUUIDsInURL(url1);
  const cleanUrl2 = (url2);

  // Compare the modified URLs
  return cleanUrl1 === cleanUrl2;
}

export const containsSimilarUrl = (targetUrl:string, urlList:string[]):boolean => {
    for (let i = 0; i < urlList.length; i++) {
        if (compareUrls(targetUrl, urlList[i])) {
            return true;
        }
    }
    return false;
}