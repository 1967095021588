import { createAsyncThunk } from '@reduxjs/toolkit';
import { commonService } from '../../services/common/common.service';

// get users list action
const getUsersListAction = createAsyncThunk(
  'salesSlice/getUsersListAction', ( payload, { dispatch, rejectWithValue }) => {
    return commonService.getUsersList()
      .then((response) => {
        return response
      })
      .catch((error: { response: unknown; }) => {
        return rejectWithValue(error?.response)
      })
  }
);

// get labels list action
const getLabelsListAction = createAsyncThunk(
  'salesSlice/getLabelsListAction', ( payload:any, { dispatch, rejectWithValue }) => {
    return commonService.getLabelsList(payload)
      .then((response) => {
        return response
      })
      .catch((error: { response: unknown; }) => {
        return rejectWithValue(error?.response)
      })
  }
);

// post new label action 
const addNewLabelAction = createAsyncThunk(
  'salesSlice/addNewLabelAction', ( payload:any, { dispatch, rejectWithValue }) => {
    return commonService.addNewLabelService(payload)
      .then((response) => {
        return response
      })
      .catch((error: { response: unknown; }) => {
        return rejectWithValue(error?.response)
      })
  }
);

// export all common actions
export const commonActions = {
    getUsersListAction,
    getLabelsListAction,
    addNewLabelAction
}