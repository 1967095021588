import RoutesWapper  from './routes/Index.routes';
import ThemeProvider from "./theme";
import "../src/assets/scss/style.scss"
function App() {
  return (
    <ThemeProvider>
      <RoutesWapper />
    </ThemeProvider>
  );
}

export default App;
