import { createSlice,PayloadAction} from '@reduxjs/toolkit';
import { commonActions } from './common.actions';
import { modifyListForAutocomplete } from "../../Utils/helperfunction/helper";

export interface loginTypes {
  loader: boolean,
  usersList: any,
  labelsList: any,
  addLabelData: any,
  error: any,
  snackbarObj: any
}

const initialState: loginTypes = {
  loader: false,
  usersList: [],
  labelsList: [],
  addLabelData: null,
  error: "",
  snackbarObj: {
    open: false
  }
};

// Common (Reducer)
export const commonSlice = createSlice({
  name: 'common',
  initialState: initialState,
  reducers: {
    showHideLoader: (state,action : PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    setSnackBar: (state: any, action: any) => {
      if (action?.payload?.message) {
        state.snackbarObj = {
          ...action.payload,
          open: true
        };
      }
    },
    resetSnackBar: (state) => {
      state.snackbarObj = {
        open: false
      };
    }
  },
  extraReducers: (builder) => {
    builder
    // -------- cases of user list api data -------
    .addCase(commonActions.getUsersListAction.pending, (state, action) => {
      state.error = "";
      state.usersList = [];
    })
    .addCase(commonActions.getUsersListAction.fulfilled, (state, action) => {
      if(action?.payload?.data?.status===200){
        state.error = "";
        state.usersList = action.payload.data.data;
      }else{
        state.usersList = [];
        state.error = "Unable to fetch users list";
      }
    })
    .addCase(commonActions.getUsersListAction.rejected, (state, action:any) => {
      state.usersList = [];
      state.error = action?.payload?.data?.message || "Unable to fetch users list";
    })
    // -------- cases of labels list api data -------
    .addCase(commonActions.getLabelsListAction.pending, (state, action) => {
      state.error = "";
      state.labelsList = [];
    })
    .addCase(commonActions.getLabelsListAction.fulfilled, (state, action) => {
      if(action?.payload?.data?.status===200){
        state.error = "";
        // modify incoming data before adding to the store
        state.labelsList = modifyListForAutocomplete(action?.payload?.data?.data); 
      }else{
        state.labelsList = [];
        state.error = "Unable to fetch users list";
      }
    })
    .addCase(commonActions.getLabelsListAction.rejected, (state, action:any) => {
      state.labelsList = [];
      state.error = action?.payload?.data?.message || "Unable to fetch users list";
    })

    // -------- cases of add label api data -------
    .addCase(commonActions.addNewLabelAction.pending, (state, action) => {
      state.addLabelData = null;
      state.error = "";
    })
    .addCase(commonActions.addNewLabelAction.fulfilled, (state, action) => {
      if(action?.payload?.data?.status===200){
        state.addLabelData = action?.payload?.data;
        state.error = "";
      }else{
        state.addLabelData = null;
        state.error = "Unable to fetch users list";
      }
    })
    .addCase(commonActions.addNewLabelAction.rejected, (state, action:any) => {
      state.addLabelData = null;
      state.error = action?.payload?.data?.message || "Unable to fetch users list";
    })
  }
});

export const { showHideLoader, setSnackBar, resetSnackBar } = commonSlice.actions;

export default commonSlice.reducer;
 
 

